import React, { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import NavigationBar, { SLIDE_PACKAGES, PACKAGE_DEFINITIONS } from './NavigationBar';
import { slides } from '../data/slides';
import { useSwipeable } from 'react-swipeable';

const PresentationDeck = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [skipAnimations, setSkipAnimations] = useState(false);
  const [direction, setDirection] = useState(0);
  const [currentPackage, setCurrentPackage] = useState(SLIDE_PACKAGES.FULL_SHOW);
  const [swipeProgress, setSwipeProgress] = useState(0);
  const [isSwipeActive, setIsSwipeActive] = useState(false);

  const handleSelectPackage = useCallback((packageKey) => {
    setCurrentPackage(packageKey);
    if (packageKey !== SLIDE_PACKAGES.FULL_SHOW) {
      const firstSlideIndex = slides.findIndex(
        slide => PACKAGE_DEFINITIONS[packageKey].slides.includes(slide.id)
      );
      if (firstSlideIndex !== -1) {
        setCurrentSlide(firstSlideIndex);
      }
    }
  }, []);

  const handleNext = useCallback(() => {
    if (currentPackage === SLIDE_PACKAGES.FULL_SHOW) {
      const currentSlideData = slides[currentSlide];
      if (currentSlideData.totalSubSteps && currentSubStep < currentSlideData.totalSubSteps - 1) {
        setCurrentSubStep(prev => prev + 1);
      } else if (currentSlide < slides.length - 1) {
        setDirection(1);
        setCurrentSlide(prev => prev + 1);
        setCurrentSubStep(0);
      }
    } else {
      const packageSlides = PACKAGE_DEFINITIONS[currentPackage].slides;
      const currentSlideId = slides[currentSlide].id;
      const currentIndexInPackage = packageSlides.indexOf(currentSlideId);
      
      if (currentIndexInPackage < packageSlides.length - 1) {
        const nextSlideId = packageSlides[currentIndexInPackage + 1];
        const nextSlideIndex = slides.findIndex(slide => slide.id === nextSlideId);
        setDirection(1);
        setCurrentSlide(nextSlideIndex);
        setCurrentSubStep(0);
      }
    }
  }, [currentPackage, currentSlide, currentSubStep]);

  const handlePrev = useCallback(() => {
    if (currentPackage === SLIDE_PACKAGES.FULL_SHOW) {
      if (currentSlide > 0) {
        const currentSlideData = slides[currentSlide];
        if (currentSlideData.totalSubSteps && currentSubStep > 0) {
          setCurrentSubStep(prev => prev - 1);
        } else {
          setDirection(-1);
          setCurrentSlide(prev => prev - 1);
          const prevSlideData = slides[currentSlide - 1];
          setCurrentSubStep(prevSlideData.totalSubSteps ? prevSlideData.totalSubSteps - 1 : 0);
        }
      }
    } else {
      const packageSlides = PACKAGE_DEFINITIONS[currentPackage].slides;
      const currentSlideId = slides[currentSlide].id;
      const currentIndexInPackage = packageSlides.indexOf(currentSlideId);
      
      if (currentIndexInPackage > 0) {
        const prevSlideId = packageSlides[currentIndexInPackage - 1];
        const prevSlideIndex = slides.findIndex(slide => slide.id === prevSlideId);
        setDirection(-1);
        setCurrentSlide(prevSlideIndex);
        setCurrentSubStep(0);
      }
    }
  }, [currentPackage, currentSlide, currentSubStep]);

  const handleSlideSelect = useCallback((index) => {
    setDirection(index > currentSlide ? 1 : -1);
    setCurrentSlide(index);
    setCurrentSubStep(0);
  }, [currentSlide]);

  const handleKeyDown = useCallback((event) => {
    // Ignore if typing in input
    if (event.target.tagName === 'INPUT') return;

    // Navigation shortcuts
    if (event.key === 'ArrowRight' || event.key === ' ') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrev();
    }

    // Package shortcuts
    const packageShortcuts = {
      '1': SLIDE_PACKAGES.FULL_SHOW,
      '2': SLIDE_PACKAGES.REBA_LEGACY,
      '3': SLIDE_PACKAGES.DOWN_HOME,
      '4': SLIDE_PACKAGES.THE_ASK
    };

    if (packageShortcuts[event.key]) {
      handleSelectPackage(packageShortcuts[event.key]);
    }

    // View mode shortcuts - Updated to handle both state and animations
    if (event.key.toLowerCase() === 'f') {
      setSkipAnimations(false);
      // Force ViewingOptions to update its state
      const viewingOptionsEvent = new CustomEvent('viewModeChange', { 
        detail: { mode: 'full' } 
      });
      window.dispatchEvent(viewingOptionsEvent);
    } else if (event.key.toLowerCase() === 'q') {
      setSkipAnimations(true);
      // Force ViewingOptions to update its state
      const viewingOptionsEvent = new CustomEvent('viewModeChange', { 
        detail: { mode: 'quick' } 
      });
      window.dispatchEvent(viewingOptionsEvent);
    }
  }, [handleNext, handlePrev, handleSelectPackage]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [
    currentSlide, 
    currentSubStep, 
    handleNext, 
    handlePrev, 
    handleSelectPackage, 
    setSkipAnimations
  ]);

  const currentSlideData = slides[currentSlide];

  // Add swipe handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    onSwiping: (e) => {
      e.preventDefault();
      setIsSwipeActive(true);
      const progress = Math.min(Math.abs(e.deltaX) / 400, 0.3);
      setSwipeProgress(e.dir === 'Left' ? -progress : progress);
    },
    onSwipeEnd: () => {
      setIsSwipeActive(false);
      setSwipeProgress(0);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    delta: 10,
    swipeDuration: 500,
    touchEventOptions: { passive: false }
  });

  return (
    <div 
      className="h-screen bg-black overflow-hidden relative touch-none select-none"
      {...swipeHandlers}
      onTouchStart={(e) => e.preventDefault()}
      onTouchMove={(e) => e.preventDefault()}
    >
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentSlide}
          custom={direction}
          className="h-full bg-black touch-none select-none"
          style={{
            x: isSwipeActive ? `${swipeProgress * 100}px` : 0,
            opacity: isSwipeActive ? 1 - Math.abs(swipeProgress) * 0.2 : 1,
            WebkitTapHighlightColor: 'transparent',
            WebkitTouchCallout: 'none',
            WebkitUserSelect: 'none'
          }}
          transition={{ 
            type: 'spring', 
            stiffness: 300, 
            damping: 30,
            opacity: { duration: 0.2 }
          }}
        >
          {currentSlideData.component({ 
            skipAnimations, 
            currentSubStep,
            direction 
          })}
        </motion.div>
      </AnimatePresence>

      {/* Swipe Indicators - Mobile Only */}
      <AnimatePresence>
        {isSwipeActive && (
          <>
            {/* Left Indicator */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ 
                opacity: swipeProgress > 0 ? swipeProgress * 0.5 : 0 
              }}
              exit={{ opacity: 0 }}
              className="fixed left-4 top-1/2 -translate-y-1/2 md:hidden"
            >
              <div className="bg-white/10 backdrop-blur-sm rounded-full p-4">
                <ArrowLeft className="w-6 h-6 text-white" />
              </div>
            </motion.div>

            {/* Right Indicator */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ 
                opacity: swipeProgress < 0 ? Math.abs(swipeProgress) * 0.5 : 0 
              }}
              exit={{ opacity: 0 }}
              className="fixed right-4 top-1/2 -translate-y-1/2 md:hidden"
            >
              <div className="bg-white/10 backdrop-blur-sm rounded-full p-4">
                <ArrowRight className="w-6 h-6 text-white" />
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <NavigationBar
        slides={slides}
        currentSlide={currentSlide}
        onSlideSelect={handleSlideSelect}
        onToggleAnimations={setSkipAnimations}
        onSelectPackage={handleSelectPackage}
        currentPackage={currentPackage}
        onNext={handleNext}
        onPrev={handlePrev}
      />
    </div>
  );
};

export default PresentationDeck; 
import React from 'react';
import { motion } from 'framer-motion';

const RevenueProjections = ({ skipAnimations }) => {
  const years = ['2025', '2026', '2027', '2028', '2029'];
  const projections = [0.5, 4.5, 9.5, 16, 23.5];

  return (
    <div className="space-y-6">
      <h3 className="text-xl text-white/90 mb-4">2.5% Ownership Value Growth (Millions)</h3>
      <div className="relative h-64">
        <div className="absolute inset-0 flex flex-col justify-between">
          {[...Array(5)].map((_, i) => (
            <div 
              key={i} 
              className="border-t border-white/10 w-full"
            />
          ))}
        </div>

        <div className="absolute inset-0 flex items-end justify-around">
          {projections.map((value, index) => {
            const height = (value / Math.max(...projections)) * 100;
            return (
              <motion.div
                key={years[index]}
                className="relative w-16"
                initial={skipAnimations ? {} : { height: 0 }}
                animate={{ height: `${height}%` }}
                transition={{ duration: 1, delay: index * 0.2 }}
              >
                <motion.div 
                  className="absolute -top-8 left-1/2 -translate-x-1/2"
                  initial={skipAnimations ? {} : { opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: (index * 0.2) + 0.5 }}
                >
                  <p className="text-white font-bold whitespace-nowrap">
                    ${value}M
                  </p>
                </motion.div>

                <div className="w-full h-full">
                  <div className="h-full w-full bg-gradient-to-t from-red-600/80 to-red-500/80 rounded-t-lg" />
                </div>

                <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                  <p className="text-white/60 text-sm">
                    {years[index]}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RevenueProjections; 
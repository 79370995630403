import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useMotionValue } from 'framer-motion';
import { Play, FastForward, Menu, X, ArrowLeft, ArrowRight } from 'lucide-react';
import KeyboardShortcuts from './KeyboardShortcuts';

export const SLIDE_PACKAGES = {
  FULL_SHOW: 'full-show',
  REBA_LEGACY: 'reba-legacy',
  DOWN_HOME: 'down-home',
  THE_ASK: 'the-ask'
};

export const PACKAGE_DEFINITIONS = {
  [SLIDE_PACKAGES.FULL_SHOW]: {
    title: "Full Show",
    slides: "all"
  },
  [SLIDE_PACKAGES.REBA_LEGACY]: {
    title: "Introduction & Reba's Legacy",
    slides: [
      'opening', 'except', 'event-intro', 'logo-reveal', 'invitation',
      'oklahoma-legacy', 'clark-legacy', 'young-reba', 'nfr-1974',
      'homecoming', 'authenticity'
    ]
  },
  [SLIDE_PACKAGES.DOWN_HOME]: {
    title: "About Down Home & Why Rodeo",
    slides: [
      'down-home', 'down-home-intro-2', 'skydance', 'western-culture',
      'best-of-both', 'rodeo-business', 'nashville-legacy', 'nashville-rodeo',
      'rodeo-music', 'team', 'hype'
    ]
  },
  [SLIDE_PACKAGES.THE_ASK]: {
    title: "The Ask & Financials",
    slides: [
      'the-ask', 'offering', 'ownership-value', 'growth-timeline',
      'expansion-plans', 'financial-model', 'personal-letter'
    ]
  }
};

// Mobile Menu Component - Updated with viewing options
const MobileMenu = ({ 
  isOpen, 
  onClose, 
  slides, 
  currentSlide, 
  onSlideSelect, 
  onSelectPackage,
  currentPackage 
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100%" }}
          className="fixed inset-0 bg-zinc-900/95 z-[100]"
        >
          <div className="flex flex-col h-full">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b border-zinc-800">
              <h2 className="text-white font-medium">Navigation</h2>
              <button onClick={onClose} className="p-2">
                <X className="w-6 h-6 text-white" />
              </button>
            </div>

            {/* Package Selection */}
            <div className="p-4 border-b border-zinc-800">
              <h3 className="text-sm text-white/60 mb-3">Select Package</h3>
              <div className="grid grid-cols-1 gap-2">
                {Object.entries(PACKAGE_DEFINITIONS).map(([key, pkg]) => (
                  <button
                    key={key}
                    onClick={() => {
                      onSelectPackage(key);
                      onClose();
                    }}
                    className={`p-3 rounded-lg text-left transition-colors ${
                      currentPackage === key 
                        ? 'bg-red-600 text-white' 
                        : 'bg-zinc-800/50 text-white/60 hover:bg-zinc-700/50 hover:text-white'
                    }`}
                  >
                    <span className="text-sm font-medium">{pkg.title}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Slides List */}
            <div className="flex-1 overflow-y-auto p-4">
              <h3 className="text-sm text-white/60 mb-3">All Slides</h3>
              <div className="grid gap-2">
                {slides.map((slide, index) => {
                  const isInCurrentPackage = currentPackage === SLIDE_PACKAGES.FULL_SHOW || 
                    PACKAGE_DEFINITIONS[currentPackage].slides.includes(slide.id);
                  
                  return (
                    <button
                      key={slide.id}
                      onClick={() => {
                        onSlideSelect(index);
                        onClose();
                      }}
                      className={`p-4 rounded-lg text-left transition-colors ${
                        currentSlide === index 
                          ? 'bg-red-600/20 text-white' 
                          : isInCurrentPackage
                            ? 'text-white/80 hover:bg-zinc-800'
                            : 'text-white/30 hover:bg-zinc-800/50'
                      }`}
                    >
                      <div className="flex items-center space-x-3">
                        <span className="text-sm font-medium">
                          {String(index + 1).padStart(2, '0')}
                        </span>
                        <span className="flex-1">{slide.title || slide.id.replace(/-/g, ' ')}</span>
                        {isInCurrentPackage && (
                          <span className="text-xs px-2 py-1 rounded-full bg-red-600/10 text-red-400">
                            Current Package
                          </span>
                        )}
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// ViewingOptions Component - Simplified for mobile
const ViewingOptions = ({ onToggleAnimations }) => {
  const [activeMode, setActiveMode] = useState('full');

  // Listen for view mode changes from keyboard shortcuts
  useEffect(() => {
    const handleViewModeChange = (e) => {
      setActiveMode(e.detail.mode);
    };

    window.addEventListener('viewModeChange', handleViewModeChange);
    return () => window.removeEventListener('viewModeChange', handleViewModeChange);
  }, []);

  const handleModeChange = (mode) => {
    setActiveMode(mode);
    onToggleAnimations(mode === 'quick');
  };

  return (
    <div className="flex items-center">
      <div className="bg-zinc-800/50 rounded-lg p-0.5 backdrop-blur-sm">
        {/* Mobile View */}
        <div className="flex sm:hidden gap-0.5">
          <button 
            onClick={() => handleModeChange('full')}
            className={`px-2 py-1.5 rounded-md flex items-center gap-1 transition-all duration-200 ${
              activeMode === 'full' 
                ? 'bg-red-600 text-white shadow-lg' 
                : 'text-white/60 hover:text-white hover:bg-zinc-700/50'
            }`}
          >
            <Play className="w-3.5 h-3.5" />
            <span className="text-xs">Full</span>
          </button>

          <button 
            onClick={() => handleModeChange('quick')}
            className={`px-2 py-1.5 rounded-md flex items-center gap-1 transition-all duration-200 ${
              activeMode === 'quick' 
                ? 'bg-red-600 text-white shadow-lg' 
                : 'text-white/60 hover:text-white hover:bg-zinc-700/50'
            }`}
          >
            <FastForward className="w-3.5 h-3.5" />
            <span className="text-xs">Quick</span>
          </button>
        </div>

        {/* Desktop View */}
        <div className="hidden sm:flex flex-row gap-0.5">
          <button 
            onClick={() => handleModeChange('full')}
            className={`px-2.5 py-1.5 rounded-md text-sm flex items-center gap-1.5 transition-all duration-200 ${
              activeMode === 'full' 
                ? 'bg-red-600 text-white shadow-lg' 
                : 'text-white/60 hover:text-white hover:bg-zinc-700/50'
            }`}
          >
            <Play className="w-3.5 h-3.5" />
            <div className="flex flex-col items-start">
              <span className="text-xs">Full Show</span>
              <span className="text-[9px] opacity-75">with animations</span>
            </div>
          </button>

          <button 
            onClick={() => handleModeChange('quick')}
            className={`px-2.5 py-1.5 rounded-md text-sm flex items-center gap-1.5 transition-all duration-200 ${
              activeMode === 'quick' 
                ? 'bg-red-600 text-white shadow-lg' 
                : 'text-white/60 hover:text-white hover:bg-zinc-700/50'
            }`}
          >
            <FastForward className="w-3.5 h-3.5" />
            <div className="flex flex-col items-start">
              <span className="text-xs">Quick View</span>
              <span className="text-[9px] opacity-75">skip animations</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

const NavigationBar = ({ 
  slides, 
  currentSlide, 
  onSlideSelect, 
  onToggleAnimations,
  onSelectPackage,
  currentPackage,
  onNext,
  onPrev
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const x = useMotionValue(0);

  // Handle mobile menu
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  // Update tooltip styles in the dots section
  const tooltipStyles = "absolute bottom-full mb-2 hidden group-hover:block z-[100]";

  const isFirstSlide = currentPackage === SLIDE_PACKAGES.FULL_SHOW 
    ? currentSlide === 0
    : PACKAGE_DEFINITIONS[currentPackage].slides.indexOf(slides[currentSlide].id) === 0;

  const isLastSlide = currentPackage === SLIDE_PACKAGES.FULL_SHOW
    ? currentSlide === slides.length - 1
    : PACKAGE_DEFINITIONS[currentPackage].slides.indexOf(slides[currentSlide].id) === 
      PACKAGE_DEFINITIONS[currentPackage].slides.length - 1;

  // Handle swipe
  const handleDragEnd = (event, info) => {
    const SWIPE_THRESHOLD = 50;
    if (Math.abs(info.offset.x) > SWIPE_THRESHOLD) {
      if (info.offset.x > 0 && !isFirstSlide) {
        onPrev();
      } else if (info.offset.x < 0 && !isLastSlide) {
        onNext();
      }
    }
  };

  return (
    <>
      <KeyboardShortcuts />
      <MobileMenu 
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        slides={slides}
        currentSlide={currentSlide}
        onSlideSelect={onSlideSelect}
        onSelectPackage={onSelectPackage}
        currentPackage={currentPackage}
      />

      <motion.div 
        className="fixed bottom-0 left-0 right-0 z-[90]"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.2}
        onDragEnd={handleDragEnd}
        style={{ x }}
      >
        <div className="bg-zinc-900/95 backdrop-blur-sm border-t border-zinc-800">
          <div className="h-16 flex items-center justify-between px-2 sm:px-4 md:px-6 max-w-screen-2xl mx-auto">
            {/* Left Section */}
            <div className="flex items-center w-[72px] sm:w-[88px]">
              <button 
                onClick={() => setIsMobileMenuOpen(true)}
                className="p-1.5 sm:p-2 text-white/60 hover:text-white"
              >
                <Menu className="w-5 h-5 sm:w-6 sm:h-6" />
              </button>

              <div className="ml-2 hidden sm:block">
                <ViewingOptions onToggleAnimations={onToggleAnimations} />
              </div>
            </div>

            {/* Center Section - Navigation Controls */}
            <div className="flex items-center justify-center gap-1 sm:gap-4 max-w-[calc(100%-144px)]">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={onPrev}
                disabled={isFirstSlide}
                className={`shrink-0 text-white/80 transition-colors bg-zinc-800 rounded-full p-1.5 sm:p-2 ${
                  isFirstSlide 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:text-white hover:bg-zinc-700'
                }`}
              >
                <ArrowLeft className="w-4 h-4" />
              </motion.button>

              <div className="flex flex-col items-center min-w-0 overflow-hidden">
                {/* Progress Numbers - Always visible */}
                <div className="text-white/80 text-[10px] sm:text-sm font-medium mb-1 truncate">
                  {currentPackage === SLIDE_PACKAGES.FULL_SHOW ? (
                    // Full show progress
                    `${String(currentSlide + 1).padStart(2, '0')} / ${String(slides.length).padStart(2, '0')}`
                  ) : (
                    // Package progress
                    (() => {
                      const packageSlides = PACKAGE_DEFINITIONS[currentPackage].slides;
                      const currentSlideId = slides[currentSlide].id;
                      const currentIndexInPackage = packageSlides.indexOf(currentSlideId) + 1;
                      return `${String(currentIndexInPackage).padStart(2, '0')} / ${String(packageSlides.length).padStart(2, '0')}`;
                    })()
                  )}
                </div>

                {/* Dots - Hidden on mobile */}
                <div className="hidden sm:flex items-center gap-0.5 sm:gap-1 overflow-x-auto no-scrollbar">
                  {currentPackage === SLIDE_PACKAGES.FULL_SHOW ? (
                    // Full show dots
                    slides.map((slide, index) => (
                      <button
                        key={index}
                        onClick={() => onSlideSelect(index)}
                        className="group relative"
                      >
                        <div className={`h-1 rounded-full transition-all duration-300 ${
                          index === currentSlide 
                            ? 'w-4 sm:w-6 bg-red-600' 
                            : 'w-1 sm:w-1.5 bg-zinc-700 hover:bg-zinc-500'
                        }`} />
                        
                        {/* Tooltip */}
                        <div className={`${tooltipStyles} bg-zinc-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap`}>
                          {slide.title || slide.id.replace(/-/g, ' ')}
                        </div>
                      </button>
                    ))
                  ) : (
                    // Package dots
                    PACKAGE_DEFINITIONS[currentPackage].slides.map((slideId, index) => {
                      const slideIndex = slides.findIndex(slide => slide.id === slideId);
                      const slide = slides[slideIndex];
                      const isCurrentSlide = currentSlide === slideIndex;
                      
                      return (
                        <button
                          key={slideId}
                          onClick={() => onSlideSelect(slideIndex)}
                          className="group relative"
                        >
                          <div className={`h-1 rounded-full transition-all duration-300 ${
                            isCurrentSlide
                              ? 'w-4 sm:w-6 bg-red-600' 
                              : 'w-1 sm:w-1.5 bg-zinc-700 hover:bg-zinc-500'
                          }`} />
                          
                          {/* Tooltip */}
                          <div className={`${tooltipStyles} bg-zinc-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap`}>
                            {slide.title || slideId.replace(/-/g, ' ')}
                          </div>
                        </button>
                      );
                    })
                  )}
                </div>
              </div>

              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={onNext}
                disabled={isLastSlide}
                className={`shrink-0 text-white/80 transition-colors bg-zinc-800 rounded-full p-1.5 sm:p-2 ${
                  isLastSlide 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:text-white hover:bg-zinc-700'
                }`}
              >
                <ArrowRight className="w-4 h-4" />
              </motion.button>
            </div>

            {/* Right Section - For balance */}
            <div className="w-[72px] sm:w-[88px]" />
          </div>
        </div>
      </motion.div>

      <div className="fixed bottom-24 left-0 right-0 flex justify-center sm:hidden z-[90]">
        <ViewingOptions onToggleAnimations={onToggleAnimations} />
      </div>
    </>
  );
};

export default NavigationBar; 
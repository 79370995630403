import React from 'react';
import { motion } from 'framer-motion';

const RodeoStat = ({ logo, name, revenue, attendance, delay = 0 }) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.8, delay }}
    className="flex flex-col md:flex-row items-center gap-3 md:gap-8 bg-white/10 backdrop-blur-sm rounded-xl md:rounded-full p-4 md:pl-4 md:pr-8"
  >
    <div className="flex items-center gap-3 md:gap-4">
      <img src={logo} alt={name} className="w-10 h-10 md:w-12 md:h-12" />
      <span className="text-white/80 font-bold text-base sm:text-lg md:text-xl text-center md:text-left">
        {name}
      </span>
    </div>
    <div className="flex-1 grid grid-cols-2 md:flex md:flex-row items-center gap-4 md:justify-end mt-4 md:mt-0">
      <div className="flex flex-col items-center md:flex-row md:items-center gap-1 md:gap-4">
        <span className="text-2xl sm:text-3xl md:text-4xl font-bold text-white">{revenue}</span>
        <span className="text-xs sm:text-sm md:text-base text-white/60 text-center">Annual Revenue</span>
      </div>
      <div className="flex flex-col items-center md:flex-row md:items-center gap-1 md:gap-4">
        <span className="text-2xl sm:text-3xl md:text-4xl font-bold text-white">{attendance}</span>
        <span className="text-xs sm:text-sm md:text-base text-white/60 text-center">Attendance</span>
      </div>
    </div>
  </motion.div>
);

const RodeoBusinessSlide = ({ skipAnimations }) => {
  const rodeos = [
    {
      logo: "/images/logos/san-antonio.png",
      name: "SAN ANTONIO RODEO",
      revenue: "$50M",
      attendance: "1.5M",
    },
    {
      logo: "/images/logos/calgary.png",
      name: "CALGARY STAMPEDE",
      revenue: "$97M",
      attendance: "1.3M",
    },
    {
      logo: "/images/logos/houston.png",
      name: "RODEO HOUSTON",
      revenue: "$336M",
      attendance: "2.5M",
    },
  ];

  return (
    <div className="relative h-screen w-full overflow-hidden bg-black">
      {/* Background with lighter overlay */}
      <div className="absolute inset-0">
        <img 
          src="/images/rodeos/arena-dark.jpg" 
          alt="Dark Arena"
          className="w-full h-full object-cover opacity-90"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/60 to-black/70" />
      </div>

      {/* Content */}
      <div className="relative z-10 h-full flex flex-col items-center justify-center px-4 sm:px-6 md:px-8">
        <div className="max-w-6xl w-full space-y-6 sm:space-y-8 md:space-y-12">
          {/* Title */}
          <motion.div
            initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center space-y-3 sm:space-y-4"
          >
            <h2 className="text-2xl sm:text-3xl md:text-5xl lg:text-6xl text-white font-bold px-2">
              AS A RESULT, THE RODEO BUSINESS
            </h2>
            <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-8xl font-bold text-red-600 font-western">
              IS BOOMING.
            </h1>
          </motion.div>

          {/* Stats */}
          <div className="space-y-3 sm:space-y-4 w-full">
            {rodeos.map((rodeo, index) => (
              <RodeoStat
                key={rodeo.name}
                {...rodeo}
                delay={skipAnimations ? 0 : 0.4 + (index * 0.2)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RodeoBusinessSlide; 
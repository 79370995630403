import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Building2, Store, Music, Home } from 'lucide-react';

const GrowthTimeline = ({ skipAnimations, currentSubStep, onChange }) => {
  const timelineContent = [
    {
      year: 2025,
      icon: Building2,
      revenue: "$15M",
      description:
        "The journey begins at Bridgestone Arena in May 2025 with three days of world-class rodeo and musical performances.",
    },
    {
      year: 2026,
      icon: Store,
      revenue: "$25M",
      description: "Adding a family-friendly fair experience to expand our community impact.",
    },
    {
      year: 2027,
      icon: Music,
      revenue: "$40M",
      description: "Transforming into a multi-day festival celebrating Nashville's heritage.",
    },
    {
      year: 2028,
      icon: Home,
      revenue: "$100M",
      description: "Moving to our new home at Nissan Stadium, cementing our position as a Nashville tradition.",
    },
  ];

  // Ensure currentSubStep is within bounds
  const currentContent = timelineContent[currentSubStep];
  if (!currentContent) {
    console.error(`Invalid substep: ${currentSubStep}`);
    return null;
  }

  return (
    <motion.div
      className="relative z-10 flex flex-col justify-center h-full"
      initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: skipAnimations ? 0 : 0.5 }}
    >
      {/* Main Content */}
      <div className="text-center mb-20 h-[400px] relative overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentContent.year}
            initial={skipAnimations ? { x: 0, opacity: 1 } : { x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={skipAnimations ? { opacity: 0 } : { x: -300, opacity: 0 }}
            transition={{
              duration: skipAnimations ? 0 : 0.5,
            }}
            className="absolute inset-0 flex flex-col items-center justify-center"
          >
            {React.createElement(currentContent.icon, {
              className: "w-24 h-24 text-red-600 mb-8",
            })}
            <div className="space-y-6 max-w-2xl mx-auto px-4">
              <div className="flex justify-center items-center gap-4">
                <p className="text-5xl text-white font-bold">{currentContent.year}</p>
                <p className="text-5xl text-red-600 font-bold">{currentContent.revenue}</p>
              </div>
              <p className="text-xl text-white/80">{currentContent.description}</p>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Navigation with Steps */}
      <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-4">
        {timelineContent.map((_, index) => (
          <button
            key={index}
            onClick={() => onChange(index)}
            className={`w-2 h-2 rounded-full transition-all duration-500 ${
              index === currentSubStep ? 'bg-red-600 w-8' : 'bg-white/30 hover:bg-white/50'
            } cursor-pointer`}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default GrowthTimeline;

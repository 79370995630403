import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { motion } from 'framer-motion';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const cities = [
  { 
    name: "Charlotte", 
    coordinates: [-80.8431, 35.2271], 
    delay: 0.5,
    labelPosition: "top"
  },
  { 
    name: "Knoxville", 
    coordinates: [-83.9207, 35.9606], 
    delay: 1.0,
    labelPosition: "bottom"
  },
  { 
    name: "New Orleans", 
    coordinates: [-90.0715, 29.9511], 
    delay: 1.5,
    labelPosition: "top"
  },
  { 
    name: "Tampa", 
    coordinates: [-82.4572, 27.9506], 
    delay: 2.0,
    labelPosition: "top"
  }
];

// Nashville coordinates
const nashville = {
  name: "MCR",
  coordinates: [-86.7816, 36.1627],
  delay: 0
};

// Mississippi River longitude (approximate)
const mississippiLongitude = -90;

// First, define the eastern states with full names
const easternStates = [
  "Alabama", "Arkansas", "Connecticut", "Delaware", "Florida", "Georgia",
  "Illinois", "Indiana", "Kentucky", "Louisiana", "Maine", "Maryland",
  "Massachusetts", "Michigan", "Mississippi", "New Hampshire", "New Jersey",
  "New York", "North Carolina", "Ohio", "Pennsylvania", "Rhode Island",
  "South Carolina", "Tennessee", "Vermont", "Virginia", "West Virginia", "Wisconsin"
];

// Update the red color to be more muted
const EASTERN_RED = "#8B0000"; // Darker, more muted red
const EASTERN_RED_HOVER = "#A52A2A"; // Slightly lighter on hover

// Update the PRCA events color to be more muted
const PRCA_COLOR = "#335577"; // More muted blue
const PRCA_TEXT_COLOR = "#667799"; // Subtle blue for text

// Add this after the cities array
const prcaEvents = [
  { name: "NFR", location: "Las Vegas", coordinates: [-115.1398, 36.1699] },
  { name: "Frontier Days", location: "Cheyenne", coordinates: [-104.8202, 41.1400] },
  { name: "Pendleton Round-Up", location: "Pendleton", coordinates: [-118.7886, 45.6721] },
  { name: "Stock Show", location: "San Antonio", coordinates: [-98.4936, 29.4241] },
  { name: "Red Bluff Round-Up", location: "Red Bluff", coordinates: [-122.2368, 40.1785] },
  { name: "Cowtown Coliseum", location: "Fort Worth", coordinates: [-97.3208, 32.7555] },
  { name: "Rodeo Salinas", location: "Salinas", coordinates: [-121.6555, 36.6777] },
  { name: "Greeley Stampede", location: "Greeley", coordinates: [-104.7091, 40.4233] },
  { name: "Reno Rodeo", location: "Reno", coordinates: [-119.8138, 39.5296] },
  { name: "Roundup Rodeo", location: "Dodge City", coordinates: [-100.0171, 37.7528] },
  { name: "Ellensburg Rodeo", location: "Ellensburg", coordinates: [-120.5478, 46.9965] },
  { name: "Night Rodeo", location: "Caldwell", coordinates: [-116.6873, 43.6629] },
  { name: "Clovis Rodeo", location: "Clovis", coordinates: [-119.7026, 36.8252] },
  { name: "St. Paul Rodeo", location: "St. Paul", coordinates: [-122.9765, 45.2098] },
  { name: "Stock Show", location: "Fort Worth", coordinates: [-97.3208, 32.7555] }
];

const USMap = () => {
  return (
    <div className="flex flex-col h-full bg-black">
      <div className="flex-1 relative px-4 md:px-8">
        {/* Map container */}
        <div className="h-full flex items-center justify-center py-4">
          {/* Traditionally text overlay - Repositioned */}
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute top-0 left-0 right-0 text-center text-sm md:text-base font-medium text-white/80 font-avenir py-4 bg-gradient-to-b from-black via-black/70 to-transparent z-20"
          >
            TRADITIONALLY, RODEOS HAVE BEEN CENTERED IN THE WESTERN UNITED STATES
          </motion.p>

          <div className="w-full aspect-[2/1] max-w-[90vw] md:max-w-[85vw]">
            <ComposableMap
              projection="geoAlbersUsa"
              projectionConfig={{ 
                scale: 1300,
                center: [-10, 5]
              }}
              style={{
                width: "100%",
                height: "100%",
                margin: "0 auto"
              }}
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map(geo => {
                    // Use the full state name instead of postal code
                    const stateName = geo.properties.name;
                    const isEastern = easternStates.includes(stateName);
                    
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={isEastern ? EASTERN_RED : "#1A1A1A"}
                        stroke="#4A4A4A"
                        style={{
                          default: {
                            fill: isEastern ? EASTERN_RED : "#1A1A1A",
                            stroke: "#4A4A4A",
                            strokeWidth: 0.5,
                            outline: "none"
                          },
                          hover: {
                            fill: isEastern ? EASTERN_RED_HOVER : "#2A2A2A",
                            stroke: "#4A4A4A",
                            strokeWidth: 0.5,
                            outline: "none"
                          }
                        }}
                      />
                    );
                  })
                }
              </Geographies>

              {/* Nashville Marker */}
              <motion.g
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <Marker coordinates={nashville.coordinates}>
                  <g transform="translate(-12, -12)">
                    {/* Larger white star for Nashville */}
                    <path
                      d="M12 0l2.4 7.8H22l-6.2 4.8 2.4 7.8L12 15.6l-6.2 4.8 2.4-7.8L2 7.8h7.6L12 0z"
                      fill="#FFFFFF"
                      className="animate-pulse"
                    />
                  </g>
                  <motion.text
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    textAnchor="middle"
                    y={-20}
                    style={{ 
                      fontFamily: 'Arial',
                      fill: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)"
                    }}
                  >
                    MCR
                  </motion.text>
                </Marker>
              </motion.g>

              {/* Expansion City Markers */}
              {cities.map(({ name, coordinates, delay, labelPosition }) => (
                <motion.g
                  key={name}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay, duration: 0.5 }}
                >
                  <Marker coordinates={coordinates}>
                    <g className="animate-pulse">
                      <circle r="5" fill="#FFFFFF" />
                      <circle r="4" fill="#DC2626" />
                      <circle r="8" fill="#DC2626" fillOpacity={0.4} />
                    </g>
                    <motion.text
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: delay + 0.2 }}
                      textAnchor="middle"
                      y={labelPosition === "bottom" ? 20 : -15}
                      style={{ 
                        fontFamily: 'Arial',
                        fill: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "bold",
                        textShadow: "2px 2px 4px rgba(0,0,0,0.8)"
                      }}
                    >
                      {name}
                    </motion.text>
                  </Marker>
                </motion.g>
              ))}

              {/* Established PRCA Events */}
              {prcaEvents.map(({ name, location, coordinates }, index) => (
                <Marker key={`${name}-${location}`} coordinates={coordinates}>
                  <g className="animate-pulse">
                    <circle r="3" fill="#FFFFFF" />
                    <circle r="2" fill={PRCA_COLOR} />
                    <circle r="4" fill={PRCA_COLOR} fillOpacity={0.2} />
                  </g>
                  <motion.text
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 * index + 0.2 }}
                    textAnchor="middle"
                    y={-15}
                    style={{ 
                      fontFamily: 'Arial',
                      fill: PRCA_TEXT_COLOR,
                      fontSize: "8px",
                      fontWeight: "bold",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.8)"
                    }}
                  >
                    {name}
                  </motion.text>
                  <motion.text
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 * index + 0.2 }}
                    textAnchor="middle"
                    y={-3}
                    style={{ 
                      fontFamily: 'Arial',
                      fill: PRCA_TEXT_COLOR,
                      fontSize: "8px",
                      fontWeight: "bold",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.8)"
                    }}
                  >
                    {location}
                  </motion.text>
                </Marker>
              ))}
            </ComposableMap>
          </div>
        </div>
      </div>
    </div>
  );
};

export default USMap; 
import React from 'react';
import { motion } from 'framer-motion';
import DynamicText from './DynamicText';

const artistImages = [
  'artists_02.png',
  'artists_04.png',
  'artists_07.png',
  'artists_10.png',
  'artists_13.png',
  'artists_15.png',
  'artists_20.png',
  'artists_18.jpg'
];

const NashvilleLegacySlide = ({ skipAnimations }) => {
  return (
    <div className="relative h-screen w-full overflow-hidden bg-black">
      {/* Background Image - Fixed positioning for true fullscreen */}
      <div className="fixed inset-0">
        <img 
          src="/images/nashville/skyline.jpg"
          alt="Nashville Skyline"
          className="w-full h-full object-cover grayscale brightness-50"
          style={{ 
            objectPosition: 'center center',
            minHeight: '100vh',
            minWidth: '100vw'
          }}
        />
        <div className="absolute inset-0 bg-black/50" />
      </div>

      {/* Content Container */}
      <div className="relative z-10 flex flex-col min-h-[calc(100vh-80px)] px-4 md:px-8 py-6 md:py-12">
        {/* Mobile Layout */}
        <div className="md:hidden flex flex-col h-full">
          {/* Text Content */}
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-4 sm:space-y-6 text-center mb-8"
          >
            <DynamicText>
              <p className="text-3xl sm:text-4xl text-red-600 font-bold tracking-wider">
                NASHVILLE
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 500}>
              <p className="text-xl sm:text-2xl text-white font-light tracking-wide">
                HAS BEEN DRIVING RODEO
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 1000}>
              <p className="text-xl sm:text-2xl text-white font-bold tracking-wide">
                SUCCESS FOR 80+ YEARS
              </p>
            </DynamicText>
          </motion.div>

          {/* Mobile Artist Grid */}
          <div className="flex-1 overflow-y-auto">
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
              {artistImages.map((image, index) => (
                <motion.div
                  key={image}
                  initial={skipAnimations ? {} : { opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ 
                    duration: 0.8,
                    delay: skipAnimations ? 0 : index * 0.1 
                  }}
                  className="relative aspect-square"
                >
                  <img 
                    src={`/images/artists/${image}`}
                    alt="Country Artist"
                    className="w-full h-full object-cover object-[center_25%] rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black/20 hover:bg-black/10 transition-colors rounded-lg" />
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* Desktop Layout */}
        <div className="hidden md:flex h-full items-center justify-between">
          {/* Text Section - Left Side */}
          <motion.div 
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="w-1/2 pr-8"
          >
            <div className="space-y-6 max-w-xl">
              <DynamicText>
                <p className="text-5xl lg:text-6xl text-red-600 font-bold tracking-wider">
                  NASHVILLE
                </p>
              </DynamicText>

              <DynamicText delay={skipAnimations ? 0 : 500}>
                <p className="text-3xl lg:text-4xl text-white font-light tracking-wide">
                  HAS BEEN DRIVING RODEO
                </p>
              </DynamicText>

              <DynamicText delay={skipAnimations ? 0 : 1000}>
                <p className="text-3xl lg:text-4xl text-white font-bold tracking-wide">
                  SUCCESS FOR 80+ YEARS
                </p>
              </DynamicText>
            </div>
          </motion.div>

          {/* Artist Photos - Right Side */}
          <motion.div 
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="w-1/2 pl-8"
          >
            <div className="grid grid-cols-3 gap-4">
              {artistImages.map((image, index) => (
                <motion.div
                  key={image}
                  initial={skipAnimations ? {} : { opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ 
                    duration: 0.8,
                    delay: skipAnimations ? 0 : index * 0.1 
                  }}
                  className="relative aspect-square"
                >
                  <img 
                    src={`/images/artists/${image}`}
                    alt="Country Artist"
                    className="w-full h-full object-cover object-[center_25%] rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black/20 hover:bg-black/10 transition-colors rounded-lg" />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default NashvilleLegacySlide; 
import React from 'react';
import { motion } from 'framer-motion';

const BestOfBothSlide = ({ skipAnimations }) => {
  return (
    <div className="relative h-screen w-full overflow-hidden bg-black">
      {/* Split Layout */}
      <div className="flex h-full">
        {/* Background Images with Overlay */}
        <div className="absolute inset-0 z-0">
          {/* Left Image - Bullfighter in Red */}
          <div className="absolute left-0 w-1/2 h-full">
            <img 
              src="/images/people/rawImage.jpg" 
              alt="Bullfighter in Red Jacket"
              className="w-full h-full object-cover"
              style={{ objectPosition: 'center 20%' }}
            />
            <div className="absolute inset-0 bg-black/30" />
          </div>
          
          {/* Right Image - Performer */}
          <div className="absolute right-0 w-1/2 h-full">
            <img 
              src="/images/people/011_RodeoDay11-Reba_03092012_MCW.jpg" 
              alt="Reba McEntire Performing"
              className="w-full h-full object-cover"
              style={{ objectPosition: 'center center' }}
            />
            <div className="absolute inset-0 bg-black/30" />
          </div>
        </div>

        {/* Center Content */}
        <motion.div
          initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="absolute inset-0 z-10 flex flex-col items-center justify-center pb-24"
        >
          <div className="space-y-8 text-center">
            {/* Main Title */}
            <div className="bg-black/90 px-12 py-8">
              <h2 className="text-4xl md:text-6xl text-white font-bold leading-tight">
                RODEO FEATURES<br />
                THE <span className="text-red-600">BEST OF BOTH.</span>
              </h2>
            </div>

            {/* Stats */}
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="bg-red-600 px-12 py-8"
            >
              <p className="text-2xl md:text-3xl text-white font-bold leading-tight">
                RODEOS SEE A 30% TICKET SALE INCREASE<br />
                WHEN CONCERTS ARE ADDED
              </p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BestOfBothSlide; 
import React from 'react';
import { motion } from 'framer-motion';

const RodeoMusicSlide = ({ skipAnimations }) => {
  const handleImageError = (e, src) => {
    console.error(`Failed to load image: ${src}`);
    e.target.style.backgroundColor = '#333';
  };

  const allImages = [
    '/images/artists/rodeo/rodeo_01.jpg',
    '/images/artists/rodeo/rodeo_02.jpg',
    '/images/artists/rodeo/rodeo_03.jpg',
    '/images/artists/rodeo/rodeo_04.jpg',
    '/images/artists/rodeo/rodeo_05.jpg',
    '/images/artists/rodeo/rodeo_06.jpg',
    '/images/artists/2/artists2_01.jpg',
    '/images/artists/2/artists2_02.jpg',
    '/images/artists/2/artists2_03.jpg',
    '/images/artists/2/artists2_05.jpg',
    '/images/artists/2/artists2_06.jpg',
    '/images/artists/2/artists2_07.jpg',
  ];

  return (
    <div className="relative w-full h-full flex items-center justify-center bg-black">
      {/* Background Image Grid */}
      <div className="fixed inset-0 w-screen h-screen">
        <div className="absolute inset-0 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 grid-rows-2">
          {allImages.map((src, index) => (
            <div
              key={src}
              className="relative w-full h-full overflow-hidden"
            >
              <img 
                src={src}
                alt=""
                className="absolute inset-0 w-full h-full object-cover brightness-75"
                onError={(e) => handleImageError(e, src)}
                style={{ 
                  objectFit: 'cover',
                  transform: 'scale(1.1)'
                }}
              />
            </div>
          ))}
        </div>
        {/* Gradient overlays */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-black/80" />
        <div className="absolute inset-0 bg-gradient-to-r from-black/60 via-transparent to-black/60" />
      </div>

      {/* Content Container */}
      <div className="relative z-10 w-full max-w-[1600px] mx-auto px-4 sm:px-6 md:px-8">
        {/* Mobile Layout */}
        <div className="md:hidden space-y-8 text-center">
          <motion.div
            initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-5xl sm:text-6xl font-bold text-red-600 leading-none tracking-tight mb-2">
              RODEO.
            </h2>
            <p className="text-lg sm:text-xl text-white/90">
              WORLD CLASS COMPETITION
            </p>
          </motion.div>

          <motion.div
            initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-5xl sm:text-6xl font-bold text-white leading-none tracking-tight mb-2">
              MUSIC.
            </h2>
            <p className="text-lg sm:text-xl text-red-600">
              BIGGEST COUNTRY MUSIC<br />
              ARTISTS IN THE WORLD
            </p>
          </motion.div>
        </div>

        {/* Desktop Layout */}
        <div className="hidden md:flex justify-between items-center">
          <motion.div
            initial={skipAnimations ? {} : { opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="flex-1 text-center"
          >
            <h2 className="text-7xl lg:text-[120px] font-bold text-red-600 leading-none tracking-tight">
              RODEO.
            </h2>
            <p className="text-xl lg:text-2xl text-white/90 mt-4">
              WORLD CLASS COMPETITION
            </p>
          </motion.div>

          <motion.div
            initial={skipAnimations ? {} : { opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="flex-1 text-center"
          >
            <h2 className="text-7xl lg:text-[120px] font-bold text-white leading-none tracking-tight">
              MUSIC.
            </h2>
            <p className="text-xl lg:text-2xl text-red-600 mt-4">
              BIGGEST COUNTRY MUSIC<br />
              ARTISTS IN THE WORLD
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default RodeoMusicSlide; 
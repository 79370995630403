import React from 'react';
import { motion } from 'framer-motion';

const PersonalLetterSlide = () => {
  return (
    <div className="relative w-full h-full bg-[#1a1a1a] flex items-center justify-center p-4 sm:p-8 pb-32">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative max-w-3xl w-full mx-auto bg-[#fff9f5] rounded-xl shadow-2xl p-6 sm:p-8 md:p-16 letter-container overflow-y-auto max-h-[calc(100vh-160px)]"
      >
        {/* Letterhead */}
        <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-8 sm:-translate-y-12">
          <img 
            src="/images/logo/mcr_logo.png" 
            alt="MCR Logo"
            className="w-20 sm:w-24 md:w-28 h-auto opacity-90"
          />
        </div>

        {/* Letter content */}
        <div className="space-y-6 sm:space-y-8 md:space-y-10" style={{ fontFamily: 'Garamond, Georgia, serif' }}>
          <div className="space-y-2 sm:space-y-3">
            <h2 className="text-3xl sm:text-3xl md:text-4xl font-normal text-red-700" style={{ fontFamily: 'Garamond, Georgia, serif' }}>
              Dear Reba,
            </h2>
            <p className="text-xs sm:text-sm text-zinc-500 italic">Nashville, Tennessee • May 2024</p>
          </div>

          <div className="space-y-6 sm:space-y-8 text-base sm:text-lg md:text-xl leading-relaxed text-zinc-800">
            <p>
              We're creating a one-of-a-kind event that celebrates Nashville's heritage 
              and the 80-year bond between country music and rodeo. The Music City Rodeo 
              will combine the thrill of the arena with the magic of music, and your voice 
              would make it unforgettable.
            </p>

            <p>
              As an icon of country music and rodeo, you embody the spirit of this event. 
              Your involvement as a performer, ambassador, or honorary figurehead would make 
              a powerful statement.
            </p>

            <div className="py-4 sm:py-6 md:py-8 px-6 sm:px-8 md:px-10 my-8 sm:my-10 md:my-12 border-l-2 border-red-700">
              <p className="text-lg sm:text-xl md:text-2xl font-normal text-red-800 italic">
                "We would be honored to discuss how we can collaborate with you to bring 
                this vision to life."
              </p>
            </div>

            <p className="text-lg sm:text-xl md:text-2xl">
              Together, we can make this an event for the ages.
            </p>
          </div>

          <div className="pt-8 sm:pt-10 md:pt-12 space-y-2">
            <p className="text-base sm:text-lg md:text-xl italic text-zinc-700">
              With great anticipation,
            </p>
            <p className="text-lg sm:text-xl md:text-2xl text-red-700" style={{ fontFamily: 'Garamond, Georgia, serif' }}>
              The Music City Rodeo Team
            </p>
          </div>

          {/* Decorative watermark */}
          <div className="absolute bottom-4 sm:bottom-6 md:bottom-8 right-4 sm:right-6 md:right-8 opacity-[0.07]">
            <img 
              src="/images/logo/mcr_logo.png" 
              alt="Watermark"
              className="w-20 sm:w-24 md:w-32 h-auto"
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default PersonalLetterSlide; 
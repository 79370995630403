import React, { useState, useEffect } from 'react';
import PresentationDeck from './components/PresentationDeck';
import './styles/globals.css';
import './styles.css';

function PasswordOverlay({ onCorrectPassword }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.toLowerCase() === 'reba') {
      localStorage.setItem('authenticated', 'true');
      onCorrectPassword();
    } else {
      setError(true);
      setPassword('');
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <div className="password-overlay">
      <div className="password-container">
        <div className="logo-container">
          <img 
            src="/images/logo/mcr_logo.png" 
            alt="Music City Rodeo"
          />
        </div>
        <h1 className="password-title">Welcome to Music City Rodeo</h1>
        <p className="password-subtitle">Enter the password to continue</p>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            className={`password-input ${error ? 'error' : ''}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            autoFocus
            autoComplete="off"
            spellCheck="false"
          />
          {error && (
            <div className="error-message">
              Incorrect password. Please try again.
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = localStorage.getItem('authenticated');
    if (auth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const preventDefaults = (e) => {
      e.preventDefault();
      return false;
    };

    const preventKeyboardShortcuts = (e) => {
      if ((e.ctrlKey || e.metaKey) && 
          (e.key === 'p' || e.key === 's' || e.key === 'u' || 
           e.key === 'c' || e.key === 'v')) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener('contextmenu', preventDefaults);
    document.addEventListener('keydown', preventKeyboardShortcuts);

    return () => {
      document.removeEventListener('contextmenu', preventDefaults);
      document.removeEventListener('keydown', preventKeyboardShortcuts);
    };
  }, []);

  return (
    <div>
      {!isAuthenticated ? (
        <PasswordOverlay onCorrectPassword={() => setIsAuthenticated(true)} />
      ) : (
        <div className="font-sans">
          <PresentationDeck />
          {/* Uncomment if you want a logout button */}
          {/* <button 
            onClick={handleLogout}
            className="fixed top-4 right-4 z-50 text-white/50 hover:text-white"
          >
            Logout
          </button> */}
        </div>
      )}
    </div>
  );
} 
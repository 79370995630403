import React from 'react';
import { motion } from 'framer-motion';

const SkydanceSlide = ({ skipAnimations }) => {
  return (
    <div className="relative h-screen w-full overflow-hidden bg-black">
      {/* Background */}
      <div className="absolute inset-0">
        <img 
          src="/images/landscapes/DH0505-2048x1143.jpeg"
          alt="Space Background"
          className="w-full h-full object-cover opacity-60"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/70 to-black/90" />
      </div>

      {/* Content Container */}
      <div className="relative z-10 flex flex-col h-full">
        {/* Header Section */}
        <div className="flex-1 flex flex-col justify-start pt-8 sm:pt-12 px-4 sm:px-8">
          <motion.div
            initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center space-y-8 sm:space-y-12 mb-12 sm:mb-20"
          >
            <div className="bg-black/80 backdrop-blur-sm px-6 py-5 sm:px-8 sm:py-6 inline-block mx-auto rounded-xl border border-white/10">
              <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-bold tracking-tight">
                Backed by<br />
                <span className="text-white">Skydance Media.</span>
              </h2>
            </div>
            <p className="text-base sm:text-lg md:text-xl text-white/90 max-w-3xl mx-auto px-4 leading-relaxed">
              Skydance's films include the box office record-breaking and Academy Award winning film <span className="text-white font-medium">Top Gun: Maverick</span>;{' '}
              <span className="text-white font-medium">Mission: Impossible – Dead Reckoning Part One</span>, <span className="text-white font-medium">Transformers: Rise of the Beasts</span>; The Family Plan, Ghosted, The
              Greatest Beer Run Ever; Heart of Stone, Spy Kids: Armageddon, The Adam Project, The Old Guard, The Tomorrow War.
            </p>
          </motion.div>
        </div>

        {/* Movie Grid */}
        <div className="w-full px-3 sm:px-6 pb-12 sm:pb-16">
          <div className="max-w-[900px] mx-auto">
            {/* Top Row */}
            <motion.div
              initial={skipAnimations ? {} : { y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="grid grid-cols-4 gap-2 sm:gap-6"
            >
              {[1, 2, 3, 4].map((num) => (
                <motion.div 
                  key={num}
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.2 }}
                  className="aspect-[3/4] relative overflow-hidden rounded-lg sm:rounded-xl shadow-lg"
                >
                  <img
                    src={`/images/slide_sd/skydance__0${num}.${num === 2 || num === 4 ? 'png' : 'jpg'}`}
                    alt={`Movie ${num}`}
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-700"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/20 to-transparent" />
                </motion.div>
              ))}
            </motion.div>

            {/* Bottom Row */}
            <motion.div
              initial={skipAnimations ? {} : { y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.7 }}
              className="grid grid-cols-4 gap-2 sm:gap-6 mt-2 sm:mt-4"
            >
              {[5, 6, 7, 8].map((num) => (
                <motion.div 
                  key={num}
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.2 }}
                  className="aspect-[3/4] relative overflow-hidden rounded-lg sm:rounded-xl shadow-lg"
                >
                  <img
                    src={`/images/slide_sd/skydance__0${num}.${num === 5 ? 'png' : 'jpg'}`}
                    alt={`Movie ${num}`}
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-700"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/20 to-transparent" />
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkydanceSlide; 
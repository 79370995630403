import React from 'react';
import { motion } from 'framer-motion';

export default function DynamicText({ children, delay = 0, className = "" }) {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.8, 
        delay: delay / 1000,
        ease: [0.21, 0.53, 0.29, 0.99]
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
} 
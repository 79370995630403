import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Keyboard } from 'lucide-react';

const SHORTCUTS = {
  'Navigation': [
    { keys: ['←', '→'], action: 'Navigate slides' },
    { keys: ['Space'], action: 'Next slide' }
  ],
  'Packages': [
    { keys: ['1'], action: 'Full Show' },
    { keys: ['2'], action: 'Reba\'s Legacy' },
    { keys: ['3'], action: 'Down Home & Rodeo' },
    { keys: ['4'], action: 'The Ask' }
  ],
  'View Modes': [
    { keys: ['F'], action: 'Full Show Mode' },
    { keys: ['Q'], action: 'Quick View Mode' }
  ],
  'Other': [
    { keys: ['Esc'], action: 'Toggle Menu' },
    { keys: ['?'], action: 'Show/Hide Shortcuts' }
  ]
};

const KeyboardShortcuts = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleKeyPress = (e) => {
      // Check for ? key (both with and without shift)
      if (e.key === '?' || e.key === '/') {
        e.preventDefault();
        setIsVisible(prev => !prev);
      }
      // Check for Escape key
      if (e.key === 'Escape') {
        e.preventDefault();
        setIsVisible(false);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Click outside to close
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isVisible && !e.target.closest('.shortcuts-container')) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isVisible]);

  // Only show on desktop
  return (
    <div className="hidden md:block">
      {/* Keyboard Icon Button */}
      <button
        onClick={() => setIsVisible(prev => !prev)}
        className="fixed bottom-24 right-4 z-50 p-2 bg-zinc-800/90 rounded-full text-white/60 hover:text-white hover:bg-zinc-700/90 transition-colors"
      >
        <Keyboard className="w-5 h-5" />
      </button>

      {/* Shortcuts Overlay */}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="shortcuts-container fixed bottom-24 right-4 z-50 bg-zinc-900/95 backdrop-blur-sm rounded-xl border border-zinc-800 shadow-xl p-6 w-80"
          >
            <div className="space-y-6">
              {Object.entries(SHORTCUTS).map(([category, shortcuts]) => (
                <div key={category}>
                  <h3 className="text-red-600 text-sm font-medium mb-2">{category}</h3>
                  <div className="space-y-2">
                    {shortcuts.map(({ keys, action }) => (
                      <div key={action} className="flex items-center justify-between text-sm">
                        <span className="text-white/60">{action}</span>
                        <div className="flex gap-1">
                          {keys.map(key => (
                            <kbd
                              key={key}
                              className="px-2 py-1 bg-zinc-800 text-white rounded min-w-[24px] text-center text-xs"
                            >
                              {key}
                            </kbd>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-4 pt-4 border-t border-zinc-800">
              <p className="text-xs text-white/40 text-center">
                Press <kbd className="px-1.5 py-0.5 bg-zinc-800 rounded text-white/60">?</kbd> to toggle shortcuts
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default KeyboardShortcuts; 
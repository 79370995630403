import React from 'react';
import { motion } from 'framer-motion';

const MobileTable = ({ data }) => (
  <div className="overflow-y-auto max-h-[55vh] pb-4 overscroll-y-contain -webkit-overflow-scrolling-touch w-full">
    <div className="space-y-3">
      {[1, 2, 3, 4, 5].map((year, yearIndex) => (
        <motion.div
          key={year}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 * yearIndex }}
          className="bg-white rounded-lg overflow-hidden border border-gray-300 w-full"
        >
          <div className="bg-[#e8e8e8] p-3 font-bold text-center sticky top-0 z-10">
            YEAR {year}
          </div>
          <div className="divide-y divide-gray-200">
            {data.map((row) => (
              <div 
                key={row.category} 
                className="flex justify-between p-3 px-4"
              >
                <span className={`text-sm ${row.category === 'TOTAL' ? 'font-bold' : ''}`}>
                  {row.category}
                </span>
                <span className={`text-sm ${row.category === 'TOTAL' ? 'font-bold text-red-600' : ''}`}>
                  {row.years[yearIndex]}
                </span>
              </div>
            ))}
          </div>
        </motion.div>
      ))}
    </div>
  </div>
);

const DesktopTable = ({ data, skipAnimations }) => (
  <div className="overflow-x-auto -mx-4 sm:mx-0">
    <div className="min-w-[640px] p-4 sm:p-0">
      <motion.table 
        className="w-full border-collapse"
        initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <thead>
          <tr>
            <th className="bg-[#e8e8e8] border border-gray-300 p-3 text-left font-bold whitespace-nowrap">
              REVENUES
            </th>
            {[1, 2, 3, 4, 5].map((year) => (
              <th 
                key={year}
                className="bg-[#e8e8e8] border border-gray-300 p-3 text-center font-bold whitespace-nowrap"
              >
                YEAR {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <motion.tr
              key={row.category}
              initial={skipAnimations ? {} : { opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 + (index * 0.1) }}
            >
              <td className="border border-gray-300 p-3 font-bold bg-white whitespace-nowrap">
                {row.category}
              </td>
              {row.years.map((value, idx) => (
                <td 
                  key={idx}
                  className={`border border-gray-300 p-3 text-center bg-white whitespace-nowrap ${
                    row.category === 'TOTAL' ? 'font-bold' : ''
                  }`}
                >
                  {value}
                </td>
              ))}
            </motion.tr>
          ))}
        </tbody>
      </motion.table>
    </div>
  </div>
);

const FinancialModelSlide = ({ skipAnimations }) => {
  const financialData = [
    {
      category: 'TICKETING',
      years: ['$5,700,000', '$5,800,000', '$11,600,000', '$74,000,000', '$162,500,000']
    },
    {
      category: 'SPONSORSHIP',
      years: ['$800,000', '$2,100,000', '$3,200,000', '$3,800,000', '$4,100,000']
    },
    {
      category: 'MERCHANDISING',
      years: ['$600,000', '$900,000', '$1,900,000', '$9,100,000', '$18,800,000']
    },
    {
      category: 'FOOD & BEVERAGE',
      years: ['-', '$400,000', '$700,000', '$1,700,000', '$2,500,000']
    },
    {
      category: 'OTHER INCOME',
      years: ['-', '$400,000', '$500,000', '$600,000', '$800,000']
    },
    {
      category: 'TOTAL',
      years: ['$7,100,000', '$9,600,000', '$17,800,000', '$89,800,000', '$188,600,000']
    }
  ];

  return (
    <div className="flex justify-center items-center min-h-screen w-full">
      <div className="w-full bg-[#f5f5f5]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative min-h-screen w-full overflow-hidden pt-16">
            <div className="relative z-10 flex flex-col h-full px-4 py-6 sm:px-6 sm:py-8 md:px-8">
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="w-full max-w-[1200px] mx-auto flex flex-col"
              >
                {/* Title */}
                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold text-black text-center mb-2 md:mb-6">
                  THE FINANCIAL MODEL
                </h1>

                {/* Tables Container */}
                <div className="flex items-start md:items-center pt-2 md:pt-0">
                  {/* Mobile View */}
                  <div className="md:hidden w-full">
                    <MobileTable data={financialData} />
                    <div className="mt-4 px-4">
                      <motion.p
                        initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.8 }}
                        className="text-sm text-gray-600 italic text-center"
                      >
                        * Projections based on historical data from comparable events
                      </motion.p>
                    </div>
                  </div>

                  {/* Desktop View */}
                  <div className="hidden md:block w-full">
                    <DesktopTable data={financialData} skipAnimations={skipAnimations} />
                    <div className="mt-6">
                      <motion.p
                        initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.8 }}
                        className="text-base text-gray-600 italic text-center"
                      >
                        * Projections based on historical data from comparable events
                      </motion.p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialModelSlide; 
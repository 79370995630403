import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

export default function CinematicSlide({ children, backgroundUrl, overlay = true, className = "" }) {
  const [brightness, setBrightness] = React.useState(100);
  
  useEffect(() => {
    if (backgroundUrl === "/images/1940s/1940.png") {
      // Start bright
      setBrightness(100);
      // After 2s, fade down
      const timer = setTimeout(() => {
        setBrightness(60);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [backgroundUrl]);

  return (
    <div className={`relative h-screen w-full overflow-hidden ${className}`}>
      <div className="absolute inset-0">
        <motion.img 
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 2.5 }}
          src={backgroundUrl} 
          alt="background" 
          className="w-full h-full object-cover"
          style={{ filter: `brightness(${brightness}%)` }}
        />
        {overlay && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 1 }}
            className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/70 to-black/80"
          />
        )}
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
        className="relative z-10 h-full flex flex-col items-center justify-center px-4 sm:px-6 md:px-8"
      >
        <div className="w-full max-w-7xl mx-auto">
          {children}
        </div>
      </motion.div>
    </div>
  );
} 
import React from 'react';
import { motion } from 'framer-motion';

const WesternCultureSlide = ({ skipAnimations }) => {
  return (
    <div className="relative h-screen w-full overflow-hidden bg-black">
      {/* Background Images with Overlay */}
      <div className="absolute inset-0 z-0">
        {/* Mobile: Stack images vertically */}
        <div className="md:hidden">
          <div className="absolute inset-0">
            {/* Single background image for mobile */}
            <div className="absolute inset-0">
              <img 
                src="/images/people/postandluke.jpg" 
                alt="Post Malone and Luke Combs"
                className="w-full h-full object-cover"
                style={{ objectPosition: 'center 25%' }}
              />
              {/* Simple dark overlay */}
              <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/75 to-black/90" />
            </div>
          </div>
        </div>

        {/* Desktop: Side by side images */}
        <div className="hidden md:block">
          <div className="absolute left-0 w-[55%] h-full">
            <img 
              src="/images/people/postandluke.jpg" 
              alt="Post Malone and Luke Combs"
              className="w-full h-full object-cover object-center"
            />
            <div className="absolute inset-0 bg-black/50" />
          </div>
          
          <div className="absolute right-0 w-[55%] h-full">
            <img 
              src="/images/people/bey.jpg" 
              alt="Beyonce Western Style"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/50" />
          </div>
        </div>
      </div>

      {/* Content Container */}
      <div className="relative z-10 h-full">
        {/* Mobile: Stack content vertically */}
        <div className="md:hidden flex flex-col h-full justify-center pb-24">
          <div className="space-y-8 px-4">
            <motion.div 
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-center"
            >
              <h2 className="text-3xl text-white font-bold mb-4">
                COUNTRY IS THE<br />
                <span className="text-red-600">HOTTEST</span> GENRE<br />
                IN MUSIC
              </h2>
              <div className="bg-red-600 p-4 inline-block">
                <p className="text-xl text-white font-bold">
                  GROWING 3X FASTER<br />
                  THAN ANY OTHER GENRE
                </p>
              </div>
            </motion.div>

            <motion.div 
              initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-center"
            >
              <h2 className="text-3xl text-white font-bold mb-4">
                WESTERN CULTURE<br />
                IS MORE <span className="text-red-600">POPULAR</span><br />
                THAN EVER
              </h2>
              <div className="bg-red-600 p-4 inline-block">
                <p className="text-xl text-white font-bold">
                  1.9B ONLINE<br />
                  IMPRESSIONS IN 2023
                </p>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Desktop: Side by side content */}
        <div className="hidden md:flex h-full">
          <motion.div 
            initial={skipAnimations ? {} : { opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="w-1/2 flex items-center justify-center p-8"
          >
            <div className="space-y-6 max-w-xl">
              <h2 className="text-4xl lg:text-5xl text-white font-bold leading-tight">
                COUNTRY IS<br />
                THE <span className="text-red-600">HOTTEST</span><br />
                GENRE IN MUSIC
              </h2>
              <div className="bg-red-600 p-6">
                <p className="text-2xl lg:text-3xl text-white font-bold leading-tight">
                  GROWING 3X FASTER<br />
                  THAN ANY OTHER<br />
                  MAJOR GENRE
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div 
            initial={skipAnimations ? {} : { opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="w-1/2 flex items-center justify-center p-8"
          >
            <div className="space-y-6 max-w-xl">
              <h2 className="text-4xl lg:text-5xl text-white font-bold leading-tight">
                WESTERN CULTURE<br />
                IS MORE <span className="text-red-600">POPULAR</span><br />
                THAN EVER
              </h2>
              <div className="bg-red-600 p-6">
                <p className="text-2xl lg:text-3xl text-white font-bold leading-tight">
                  THE TOPIC HAS DRIVEN<br />
                  1.9B ONLINE IMPRESSIONS<br />
                  SINCE 2023
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default WesternCultureSlide; 
import React from 'react';
import { motion } from 'framer-motion';
import DynamicText from './DynamicText';

const NashvilleRodeoSlide = ({ skipAnimations }) => {
  return (
    <div className="fixed inset-0 min-h-screen min-w-screen">
      {/* Background Image with Effects */}
      <div className="absolute inset-0">
        <motion.div
          initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="absolute inset-0"
        >
          <img 
            src="/images/nashville/aerial.jpg"
            alt="Nashville Aerial View"
            className="w-full h-full object-cover grayscale brightness-[0.5] contrast-[1.1]"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/30 via-black/20 to-black/30" />
        </motion.div>
      </div>

      {/* Content */}
      <div className="absolute inset-0 z-10 flex flex-col justify-center items-center">
        <div className="text-center w-full max-w-[90%] mx-auto">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-8 sm:space-y-12 font-avenir"
          >
            <DynamicText skipAnimation={skipAnimations}>
              <p className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-light tracking-wide">
                YET SOMEHOW,
              </p>
            </DynamicText>
            
            <DynamicText delay={skipAnimations ? 0 : 800}>
              <p className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-light tracking-wide">
                MUSIC CITY
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 1600}>
              <p className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-red-600 font-bold tracking-wide">
                HAS NEVER
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 2400}>
              <p className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-light tracking-wide">
                HAD A RODEO TO CALL OUR OWN.
              </p>
            </DynamicText>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default NashvilleRodeoSlide; 